<template>
  <v-container class="px-lg-10 mx-0">
    <v-row>
      <v-col>
        Nama Badan Usaha
      </v-col>

      <v-col>
        Nama Merek
      </v-col>

      <v-col>
        Partner
      </v-col>

      <v-col>
        Travikr
      </v-col>

      <v-col>
        Status
      </v-col>

      <v-col />
    </v-row>

    <v-divider />

    <v-row
      v-for="(val, i) in dataItems"
      :key="i"
      style="border-bottom: thin solid rgba(0, 0, 0, 0.12);"
      class="d-flex align-center"
    >
      <v-col>
        {{ val.name_legal }}
      </v-col>

      <v-col>
        {{ val.name }}
      </v-col>

      <v-col>
        {{ val.PRT }}
      </v-col>

      <v-col>
        {{ val.TRV }}
      </v-col>

      <v-col>
        <template v-if="val.status">
          <v-icon
            color="green"
            large
          >
            mdi-check-bold
          </v-icon>
        </template>

        <template v-else>
          <v-icon
            color="red"
            large
          >
            mdi-alert
          </v-icon>
          &nbsp;
          <span>{{ val.message }}</span>
        </template>
      </v-col>

      <v-col>
        <v-btn
          v-if="isAllowEdit"
          small
          dark
          color="purple"
          class="mr-2"
          @click="detailButtonClick(val.hash)"
        >
          DETAIL
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card>
        <v-container>
          <v-card-title class="font-weight-bold text-h3">
            Modifikasi Bagi Hasil Mitra
          </v-card-title>

          <v-divider class="mt-5" />

          <v-row class="py-3">
            <v-spacer />
          </v-row>

          <div class="px-8">
            <v-row>
              <v-col class="font-weight-bold text-h5 mb-n4">
                Nama Badan Usaha
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                {{ dataDetail.namaBadanUsaha }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="font-weight-bold text-h5 mb-n4">
                Nama Merek
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                {{ dataDetail.namaMerekDagang }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="font-weight-bold text-h5 mb-n6">
                Persentase Pembagian
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col
                sm="6"
                md="5"
              >
                <v-text-field
                  v-model="dataDetail.TRV"
                  dense
                  outlined
                  append-icon="%"
                  color="purple"
                >
                  <template v-slot:prepend>
                    Tavikr
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                sm="6"
                md="5"
              >
                <v-text-field
                  v-model="dataDetail.PRT"
                  dense
                  outlined
                  append-icon="%"
                  color="purple"
                >
                  <template v-slot:prepend>
                    Mitra
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="password"
                  type="password"
                  dense
                  outlined
                  placeholder="Password"
                  color="purple"
                />
                <span style="color: red;">
                  Harap perhatikan kembali data yang diinput,
                  data yang keliru akan berefek sangat besar
                  terhadap Mitra maupun Travikr.
                </span>
              </v-col>
            </v-row>
          </div>
          <div class="px-3">
            <v-alert
              v-model="dialogAlert"
              type="error"
              dense
              dismissible
            >
              Total persentase pembagian Mitra dan Travikr harus 100%
            </v-alert>
          </div>
          <!-- <v-card-text /> -->
          <div class="px-5 mb-5">
            <v-card-actions>
              <v-btn
                type="password"
                color="green"
                block
                @click="modifyButtonClick"
              >
                Modifikasi
              </v-btn>
            </v-card-actions>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dialog: false,
      partnercompanyfee: [],
      dataDetail: {
        hash: '',
        namaBadanUsaha: '',
        namaMerekDagang: '',
        PRT: 0,
        TRV: 0,
      },
      password: '',
      isAllowVerification: false,
      isAllowEdit: false,
      dialogAlert: false,
    }),

    computed: {
      dataItems () {
        const returnData = []
        this.partnercompanyfee.map((val, i) => {
          const item = {}
          item.hash = val.hash
          item.name = val.name
          if (val.share_percent.length === 0) {
            item.PRT = 0
            item.TRV = 0
          } else {
            item.PRT = val.share_percent.PRT
            item.TRV = val.share_percent.TRV
          }
          item.status = val.share_percent_valid
          item.message = val.share_percent_msg
          returnData.push(item)
        })
        return returnData
      },
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        // Check user menus
        const userMenus = localStorage.getItem('userMenus').split(',')
        if (userMenus.includes('PARTNER_COMPANY_FEE_EDIT')) {
          this.isAllowEdit = true
        }

        const vm = this

        const requestBody = {
          page: 1,
          limit: 25,
        }

        axios.post('/t/partner/company/fee', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.partnercompanyfee = res.data.data.list
          }
        }).catch((e) => {
        })
      },

      detailButtonClick (id) {
        const vm = this

        const requestBody = {
          prt_com_hash: id,
        }

        axios.post('/t/partner/company/fee/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            const data = res.data.data.detail
            vm.dataDetail.hash = id
            vm.dataDetail.namaBadanUsaha = data.name_legal
            vm.dataDetail.namaMerekDagang = data.name
            vm.dataDetail.PRT = data.share_percent.PRT
            vm.dataDetail.TRV = data.share_percent.TRV
          }
        }).catch((e) => {
          return false
        })
        this.dialog = true
      },

      modifyButtonClick () {
        const totalPercentage = parseInt(this.dataDetail.PRT) + parseInt(this.dataDetail.TRV)
        if (totalPercentage !== 100) {
          this.dialogAlert = true
          return
        }

        const requestBody = {
          prt_com_hash: this.dataDetail.hash,
          share_percent_prt: this.dataDetail.PRT,
          share_percent_trv: this.dataDetail.TRV,
          pwd: this.password,
        }

        axios.post('/t/partner/company/fee/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.initialize()
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
        this.password = ''
        this.dialog = false
      },
    },
  }
</script>
